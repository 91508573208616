/* blog */
article.post
{
	margin-bottom: 3em;
}

h2.entry-title
{
	margin-bottom: 0.5em;
}

.single-post .page-header {
    margin-top: 0;
}

.comment-form textarea {
    height: inherit;
}

.entry-meta > span {
    padding: 5px 15px 5px 0;
}

.entry-meta .fa {
    margin-right: 3px;
    color: $brand-darkblue;
}

.type-post .post-header {
    margin-top: 0;
}

hr.section-divider {
    @extend .clearfix;
}

.thumbnail {
    @extend .img-responsive;
}

.updated {
    display: none;
}

/* blog comments */
.comments {
    margin-top: 3em;
}

.comment-content a {
  word-wrap: break-word;
}
#respond {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0px !important;
}
#respond > p, #respond p.comment-notes, p.logged-in-as {
  margin-bottom: 10px;
}
p.logged-in-as {
  padding-bottom: 0px;
}
.comment-list {
  margin-bottom: $standardspacing !important;
  margin-left: 0px !important;
  padding-left: 0;
}
.comment .comment-body {
  background-color: #ffffff;
  margin: 0;
  overflow: hidden;
  padding: 25px;
  margin-top: 2em;
  padding-left: 116px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0);
  border-color: #E8E8E8;
  border-radius: 4px;
}
.comment-list .children {
  margin-left: 0px!important;
  padding-left: 40px;
  //border-left: 1px solid #E8E8E8;
  background-repeat: no-repeat;
  background-position: left 20px;
}
.comment-list li.comment > div img.avatar {
  position: absolute;
  left: 29px;
  top: 29px;
}
#comment-form-title {
  font-size: 24px;
  padding-bottom: 10px;
}
.vcard .avatar {
  position: absolute;
  left: 29px;
  top: 29px;
}
.comment-metadata {
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 10px;
}
.comment-reply-link {
  float: right;
}
.form-allowed-tags code {
  word-wrap: break-word;
  white-space: inherit;
}
.comment-respond label {
  display: block;
  font-weight: normal;
}
.comment-respond .required {
  color: #C7254E;
}
/* end blog */