.contact-entries
{
    @extend .text-center;
    
    @media (min-width: $screen-md-min) {
        text-align: right;
        margin-top: 10px;
    }

	span.type
	{
        @media (max-width: $screen-sm-max) {
            display: none;
        }
        
		color: $contact-entries-label;	
		margin-top: 8px;
		display:block;		
	}

	li
	{
		margin-bottom: 8px;
	}	

	ul,
	li:last-child
	{
		margin-bottom: 0;
	}

	a
	{
		color: $contact-entries-link;	
        font-size: 1.7em;
        padding: 4px;
		font-weight: bold;

		@media (min-width: $screen-sm-min) {
            font-size: 2em;
            line-height: 1.2em;
            padding: inherit;
		}
        
        &.tel{
            color: $brand-darkblue;
            font-weight: bold;
        }
        
        &.email {
            font-size: 1em;
        }
	}
}