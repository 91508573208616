
main.main {
    margin-bottom: 3em;
}

.main h2,
.main h3
{
	text-align: left;
	margin-bottom: 1em;
	margin-top: 1.5em;
}

.main h2
{
	font-size: 20px;
}

.main h2:first-child
{
	margin-top: 0;
}

.slidercontainer
{
	margin-bottom: $standardspacing;
}


.social-icon
{
	color: #DCCEB5;
	display: inline-block;
	width: 25px;
	height: 43px;
	line-height: 43px;
	font-size: 24px;
}

.social-icon:hover,
.social-icon:active
{
	color: #525252;
}

.biggs-phone {
	background: url(../images/noun_170946_cc-prepped.svg) no-repeat bottom center;
	vertical-align: -20%;
	height: 1em;
}

:not(.fa-fw).biggs-phone {
	width: 1em;
}