// variables to override
// @speechbubble-quote-backgroundcolor
// @speechbubble-quote-textcolor
// @speechbubble-attrib-textcolor

// speech bubble styles from: http://tympanus.net/Tutorials/ModernBlockQuoteStyles/index3.html
.testimonials-speechbubble
{
	.carousel-control
	{
		background-image:none !important;
		background:	#ddd;
		color:#999;
		padding: 0;
		width:26px;
		top:auto;	
		left:auto;
		bottom:0;
		opacity:1;
		text-shadow:none;
		border-radius: 10px;
	}

	.carousel-control.right
	{
		right: 10px;
	}

	.carousel-control.left
	{
		right: 40px;
	}

	.mb-wrap {
	  position: relative;
	}

	.mb-style-3 blockquote{
		position: relative;
		border-left: none;
		background: $speechbubble-quote-backgroundcolor;
		padding: 30px;
		border-radius: 10px;
		margin-bottom: 0;

		// triangle technique from http://cssarrowplease.com/
		&:after, 
		&:before  {
			top: 100%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&:after {
			border-top-color: $speechbubble-quote-backgroundcolor;
			border-width: 10px;
			left: 65%;
			margin-left: -10px;
		}

		&:before {
			border-top-color: $speechbubble-quote-backgroundcolor;
			border-width: 11px;
			left: 65%;
			margin-left: -11px;
		}
		// end of triangle

		p {
			font-style: italic;
			color: $speechbubble-quote-textcolor;

			&:first-child {
				text-indent: 80px;
				position: relative;
			}

			&:first-child:before{
				content: '\201C';
				font-family: serif;
				font-style: normal;
				font-weight: 700;
				position: absolute;
				font-size: 160px;
				top: 10px;
				left: -85px;
				color: $highlight-link-color;
			}
		}
	}

	.mb-style-3 .mb-attribution {
		text-align: right;
		padding: 20px 20px 36px;
		position: relative;
	}

	.mb-style-3 .mb-author{
		font-weight: 700;
		font-size: 18px;
		color: $speechbubble-quote-backgroundcolor;
		text-shadow: 0 1px 1px rgba(255,255,255,0.7);
	}

	
}