// typography
/////////////
a
{
	color: $link-color;
    transition: color 0.3s;
}

a:hover, 
a:active,
a:focus
{
	color: $link-hover-color;
}

h1, h2, h3, h4,
.h1, .h2, .h3, .h4
{
	font-family: $headerfont;
}


h1, .h1
{
	font-size: 34px;
	margin-bottom: .8em;
	margin-top: 0;
	text-align: center;
	font-weight: 500;
    
    @media (min-width: $screen-md-min) {
        h1, .h1	{
            font-size: 46px;

        }
    }
}

h2, .h2
{
	margin-top: 0;
	font-weight: 500;
	text-align: center;
    font-size: 26px;
    margin-bottom: 30px;
    @media (min-width: $screen-sm-min) {
	    font-size: 36px;
    }    
    @media (min-width: $screen-md-min) {
        font-size: 36px;
        margin-bottom: 60px;
    }    
    @media (min-width: $screen-lg-min) {
        &.pageheadings {
            margin-left: 5em;
            margin-right: 5em;
        }
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .section-service {
        h2 {
            font-size: 26px;
        }
    }
}

h3, .h3 {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: $standardspacinghalf;
}

h4, .h4 {
    text-transform: none;
    font-size: $font-size-text-mobile;
    line-height: 20px;
    @media (min-width: $screen-sm-min) {
	    text-transform: inherit;
        font-size: $font-size-text-normal;
        line-height: 25px;
    }  
}

body, p, li
{
	font-family: $copyfont;    
    font-size: $font-size-text-mobile;
    
    @media (min-width: $screen-sm-min) {
        font-weight: 500;
        font-size: $font-size-text-normal;
    }
}