.pestcontrolgrid {
    .row > div {
        margin-bottom: $standardspacinghalf;
        padding-bottom: 15px;

        h3 {
            margin-left: -15px;
            margin-right: -15px;
            padding: 10px 15px;
        }        
    }

    .row > div:nth-child(even) {
        background-color: $brand-lightgrey;

        h3 {
            background-color: darken($brand-lightgrey, 5%);
        }
    }

    .row > div:nth-child(odd) {
        background-color: $brand-darkblue;
        color: $brand-white;

        h3 {
            background-color: darken($brand-darkblue, 5%);
        }
    }
}