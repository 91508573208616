.btn {
    transition: background-color .5s ease-in-out, color .5s ease-in-out;
}

.btn-tall {
    padding-top: 15px;
    padding-bottom: 15px;
}

.btn-primary, 
.comment-form input[type="submit"]
{
	background-color: $primary-link-color;
	border-color: $primary-link-border-color;
}

.btn-primary:hover, 
.btn-primary:focus, 
.btn-primary.focus, 
.btn-primary:active, 
.btn-primary.active, 
.open > .dropdown-toggle.btn-primary, 
.comment-form input[type="submit"]:hover, 
.comment-form input[type="submit"]:focus, 
.comment-form input[type="submit"].focus, 
.comment-form input[type="submit"]:active, 
.comment-form input[type="submit"].active, 
.open > .dropdown-toggle.comment-form input[type="submit"]
{
	background-color: lighten($brand-darkblue, 30%);
	border-color: $brand-darkblue;
    color: #333;
}

.btn-dark-default {
    color: #fff;
    background-color: $primary-link-color;
    border: none;
    border-radius: 0;
    padding: .75em 2em;

    &:hover,
    &:focus,
    &:active {
        color: #eee;
        text-decoration: underline;
    }
}