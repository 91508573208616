.testimonials-fullwidth
{
	.testimonial
	{
		background: url(../images/quote-mark.png) no-repeat;
		padding-left: 85px;
	}

	@media (min-width: $screen-sm-min) {
		.testimonial .inner
		{
			background: url(../images/quote-mark-close.png) right top no-repeat;
			padding-right: 85px;
            border: none;
		}
	}

	.testimonial-carousel-controls a
	{
		color: #231f20;	
	}
}