@charset "UTF-8";

@font-face {
  font-family: "biggsiconfont";
  src:url("../fonts/biggsiconfont.eot");
  src:url("../fonts/biggsiconfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/biggsiconfont.woff") format("woff"),
    url("../fonts/biggsiconfont.ttf") format("truetype"),
    url("../fonts/biggsiconfont.svg#biggsiconfont") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "biggsiconfont" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="biggs-"]:before,
[class*=" biggs-"]:before {
  font-family: "biggsiconfont" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.biggs-icofont-saw:before {
  content: "\61";
}
.biggs-icofont-rainy:before {
  content: "\62";
}
.biggs-icofont-bin:before {
  content: "\63";
}
.biggs-iconfont-mop:before {
  content: "\64";
}
.biggs-icofont-animal-rabbit-running:before {
  content: "\65";
}
