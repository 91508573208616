.padded {
    padding: 15px;
}

.margintop
{
	margin-top: $standardspacing;
}

.margintail
{
	margin-bottom: $standardspacing;
}

.margintoptail
{
	margin-top: $standardspacing;
  margin-bottom: $standardspacing;
}

.margintoptailspecial
{
	margin-top: $standardspacing;
  margin-bottom: $standardspacing + $standardspacinghalf;
}

.margintophalf {
    margin-top: $standardspacinghalf;
}

.margintailhalf{
    margin-bottom: $standardspacinghalf;
}

.margintoptailhalf
{
	margin-top: $standardspacinghalf;
  margin-bottom: $standardspacinghalf;
}

.nomargintail {
    margin-bottom: 0;
}

.nopaddingtail {
    padding-bottom: 0 !important;
}

.img-borderline
{
	padding: 3px; 
	border: 1px solid #dcceb5;
}

@media (max-width: $screen-xs-max) {
  .img-xs-bottomspacinghalf {
    margin-bottom: $standardspacinghalf;
  }
}

// Full Width Containers in Limited Width Parents
// https://css-tricks.com/full-width-containers-limited-width-parents/
.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.list-chevron {
  list-style-type: none;
  
  li a {
    padding-left: 1.3em;
    
    &:before {
      content: "\f101"; /* FontAwesome Unicode */
      font: 14px FontAwesome;
      display: inline-block;
      margin-left: -1.3em; /* same as padding-left set on li */
      width: 1.3em; /* same as padding-left set on li */
    }
  }
}

.list-twocol {
	li
	{
		@include make-sm-column(6);
        padding-left: 0;
        padding-right: 0;
	}
}

.list-pipeseparator
{
	li
	{
	  padding-right: 0;

	  &:before {
			content: "| ";
			padding-right: 5px;
		}

		&:first-child:before {
			content: "";
			padding-right: 0;
		}
	}
}

// center align a list
.list-centeralign {
    width: 100%;
    text-align: center;
}

.list-centeralign > li {
    float: none; // ignore this warning it resets some scenarios
    display: inline-block;
}

// icon list
// https://codepen.io/mburnette/pen/rdGJc
// using <ul class="list-icon icon-map-marker">
ul.list-icon,
ul.list-icon li {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.list-icon {
  margin: 0;
}

ul.list-icon li {
  margin: 1em;
  margin-left: 1.5em;
}

ul.list-icon li:before {
  float: left;
  margin-left: -1.5em;
}

// add custom icons for icon-list here:
ul.icon-map-marker li:before {
  content: '\f041';
  font-family: 'FontAwesome';
}

ul.icon-check-square-o li:before {
  content: '\f046';
  font-family: 'FontAwesome';
}

// keep headings aligned
main.main h3:first-child {
    margin-top: 0;
}

// fix the sitemap
.shortcode_menu {
  margin: 0 3em !important;
}

// fix the facebook widget width
.likebox-wrapper * {
   width: 100% !important;
}

// http://stackoverflow.com/questions/20547819/vertical-align-with-bootstrap-3
.row-vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

// hide elements that are below the navbar responsive breakpoint
@media (min-width: $grid-float-breakpoint) {
		.hidden-above-breakpoint {
      display: none !important;
    }    
}

// hide elements that are above the navbar responsive breakpoint
@media (max-width: $grid-float-breakpoint - 1) {
		.hidden-below-breakpoint {
      display: none !important;
    }
}

// white gradient overlay
.whitegradientoverlay {
  position: relative;

  .whitegradientoverlay-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(255,255,255,1);
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(253,253,253,1) 9%, rgba(251,251,251,0) 20%, rgba(237,237,237,0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(9%, rgba(253,253,253,1)), color-stop(20%, rgba(251,251,251,0)), color-stop(100%, rgba(237,237,237,0)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(253,253,253,1) 9%, rgba(251,251,251,0) 20%, rgba(237,237,237,0) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(253,253,253,1) 9%, rgba(251,251,251,0) 20%, rgba(237,237,237,0) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(253,253,253,1) 9%, rgba(251,251,251,0) 20%, rgba(237,237,237,0) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(253,253,253,1) 9%, rgba(251,251,251,0) 20%, rgba(237,237,237,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed', GradientType=0 );
  }
}

// skew mask
.section-masked {
  margin-bottom: $standardspacing;

  .section-masked-inner {
    z-index: 1;
    position: relative;
  }

  .bottommask {
      padding-bottom: 3%;
      bottom: -1px;  
      z-index: 1;
      position: absolute;
      width: 100%;
      pointer-events: none;
      overflow: hidden;
      height: 0;
      left: 0;

      .bottommaskskew {
        background-color: #fff;
        transform-origin: 100% 0;
        transform: skewY(-1.6deg);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backface-visibility: hidden;
      }
  }
}


// hover effect - golliath
// from https://tympanus.net/Development/HoverEffectIdeas/index2.html
figure.effect-goliath {
	position: relative;
	overflow: hidden;
	cursor: pointer;
  max-width: 525px;
  margin-left: auto;
  margin-right: auto;
}

figure.effect-goliath img {
	position: relative;
	display: block;
	max-width: 100%;
	opacity: 0.92;
}

figure.effect-goliath figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

figure.effect-goliath figcaption::before,
figure.effect-goliath figcaption::after {
	pointer-events: none;
}

figure.effect-goliath figcaption,
figure.effect-goliath figcaption div.link,
figure.effect-goliath figcaption div.link a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
figure.effect-goliath figcaption a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

figure.effect-goliath h2 {
	word-spacing: -0.15em;
	font-weight: 300;
  text-align: left;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
}

figure.effect-goliath h2 span {
	font-weight: 800;
}

figure.effect-goliath h2,
figure.effect-goliath p {
	margin: 0;
}

figure.effect-goliath p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

figure.effect-goliath {
	background: $brand-red;
}

figure.effect-goliath img,
figure.effect-goliath h2 {
	transition: transform 0.35s;
}

figure.effect-goliath img {
	backface-visibility: hidden;
}

figure.effect-goliath h2,
figure.effect-goliath p {
	position: absolute;
	bottom: 0;
	left: 0;
  right: 0;
	padding: 15px;

  @media (min-width: $screen-lg-min) {
    padding: 30px;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  figure.effect-goliath h2{
    font-size: 1.1em;
  }
}

@media (min-width: $screen-md-min) {
  .col-md-4 figure.effect-goliath h2 {
    font-size: 1.1em;
  }

  .col-md-4 figure.effect-goliath h2 span {
    display: block;
  }
}

@media (min-width: $screen-lg-min) {
  figure.effect-goliath p {
    padding-top: 25px; 
    padding-bottom: 25px;
  }
}

figure.effect-goliath p {
	text-transform: none;
	font-size: 90%;
	opacity: 0;
	transition: opacity 0.35s, transform 0.35s;
	transform: translate3d(0,50px,0);
}

figure.effect-goliath .fa {
  transition: all 0.35s;
}

figure.effect-goliath:hover .fa {
  transition: transform 0.35s ease 0.4s;
  transform: translate3d(10px, 0, 0);
}

figure.effect-goliath:hover img {
  transform: translate3d(0,-65px,0);

  @media (min-width: $screen-lg-min) {
	  transform: translate3d(0,-80px,0);
  }
}

figure.effect-goliath:hover h2 {
	transform: translate3d(0,-100px,0);
}

figure.effect-goliath:hover p {
	opacity: 1;
	transform: translate3d(0,0,0);
}

/* for biggsfonticon */
.biggsicon {
    margin: 0;
    margin-right: 10px;
    padding: 13px;
    color: $brand-red !important;
    overflow: hidden;
    float: left;

    height: 60px;
    width: 60px;
    font-size: 40px;

    @media (min-width: $screen-md-min) {
      height: 150px;
      width: 150px;
      font-size: 120px;
    }
}

// show magnify glass on image hover
// https://gist.github.com/srikat/9aee586a8ff153028379
.expandable-image {
	position: relative;
	display: inline-block;
}

.expandable-image img {
	vertical-align: top;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: #151515; /* for dark overlay on top of the image */
	opacity: 0;
	transition: opacity 0.35s, transform 0.35s;
}

.expandable-image:hover .overlay {
	opacity: 0.8;
}

.magnifying-glass-icon {
	color: #fff;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	padding: 20px;
	border: 1px solid #fff;
	line-height: 1;
}

.magnifying-glass-icon:hover {
	background: rgba(0,0,0,0.5);
	color: #fff;
}

@media only screen and (max-width: 400px) {
	.expandable-image {
		display: block;
	}
}