.pageheadings {
    margin: -90px 0 0;
    padding: 25px 10px 50px;
    @media (min-width: $screen-sm-min) {
        padding: 75px 50px 50px;
    }
    color: $brand-white;

    background: rgba(184,56,34,1);
    background: -moz-linear-gradient(-45deg, rgba(184,56,34,1) 0%, rgba(184,56,34,1) 45%, rgba(155,16,0,1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(184,56,34,1)), color-stop(45%, rgba(184,56,34,1)), color-stop(100%, rgba(155,16,0,1)));
    background: -webkit-linear-gradient(-45deg, rgba(184,56,34,1) 0%, rgba(184,56,34,1) 45%, rgba(155,16,0,1) 100%);
    background: -o-linear-gradient(-45deg, rgba(184,56,34,1) 0%, rgba(184,56,34,1) 45%, rgba(155,16,0,1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(184,56,34,1) 0%, rgba(184,56,34,1) 45%, rgba(155,16,0,1) 100%);
    background: linear-gradient(135deg, rgba(184,56,34,1) 0%, rgba(184,56,34,1) 45%, rgba(155,16,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b83822', endColorstr='#9b1000', GradientType=1 );

    h1 {
        text-align: left;
        font-size: 28px;
        line-height: 1.5em;
    }

    h2 {
        text-align: left;
        font-size: 24px;
        line-height: 1.5em;
    }  

    .pageheadings-intro {
        display: none;

        @media (min-width: $screen-md-min) {
            display: block;
        }

        font-family: $headersansfont;
        font-size: 3em;
        font-weight: 700;
        margin-top: -.2em;
        max-width: 70%;        
    }  
}