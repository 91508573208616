
.section-footer
{
	padding-top: $standardspacing;
	padding-bottom: $standardspacing;

	h4, li, a
	{
		color: $highlight-link-color;
	}

	a
	{
		&:hover,
		&:focus
		{
			color: lighten($brand-red, 10%);
			text-decoration: underline;
		}
	}

	.widget_recent_entries ul
	{
		@extend .list-unstyled;
	}

	#menu-footer-services-menu {
		@extend .list-unstyled;
	}

	/*
	// two col footer menu
	ul#menu-footer-menu
	{
		@include make-row();
	}

	ul#menu-footer-menu li
	{
		@include make-sm-column(6);
	}
	*/

	.footer-contacts li
	{
		margin-bottom: 1em;
	}
}

.section-footer-copyright
{
	padding-top: $standardspacing;
	padding-bottom: $standardspacing;
	font-size: 0.8em;

	p, a
	{
		font-family: $copyfont;
		line-height: 1.5em;
		color: #333;
	}

	li, a, p
	{
		font-size: 1em;
	}	

	.row-footer-text {
		margin-top: $standardspacinghalf;
	}	

	.menu-copyright {
		margin-top: 1em;

		@media(min-width: $screen-md-min) {
			margin-top: 0;
			float: right;
		}
	}		
}