// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// colours
$brand-red:                         #B83822;
$brand-darkblue:                    #014264;
$brand-lightgrey:                   #F4F7F8;
$brand-grey:                        #BCBDC0;
$brand-white:                       #fff;
$brand-darkgrey:                    #23282D;

$brand-primary:                     $brand-darkblue;
$primary-link-color:				$brand-red;
$primary-link-border-color:			darken($primary-link-color, 15%);

//$primary-link-color-hover:		lighten($highlight-bg-color, 15%);
//$primary-link-border-color-hover:	darken($highlight-bg-color, 15%);

$primary-bg-color: 					#eee;


$highlight-bg-color: 				#1b1d8a;
$highlight-link-color:				#fff;
$highlight-link-color-hover:		$primary-link-color;

$secondary-bg-lowcontrast:			$brand-darkblue;

// font sizes
$font-size-before-mobile: 10px;
$font-size-before-normal: 14px;
$font-size-text-mobile: 14px;
$font-size-text-normal: 18px;

// margins
// used by util classes
$standardspacing:                   50px;
$standardspacinghalf:               ($standardspacing / 2);

// typography
// Dont forget: If you specify a Google Font below, you need to register it in .lib/setup.php/GOOGLE_FONTS
$headerfont: 						Georgia, Times, 'Times New Roman', serif;
$headersansfont:                    'Quicksand', "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif;
$copyfont: 							'Raleway', "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif;
$link-color:                        $brand-red;
$link-hover-color:                  darken($brand-red, 10%);

// the outline / stroke colour for form elements with focus
$form-focus-color:                  $brand-red;

// bootstrap overrides

// uncomment for custom navbar break
// note: can't use $screen-XX-min variables as BS not included yet
// $grid-float-breakpoint: 992px; 

$navbar-default-link-hover-color:	#000;
$navbar-default-link-active-color:	#000;

$nav-link-hover-bg:                 $brand-darkblue;
$dropdown-bg:                       $brand-darkblue;
$dropdown-link-color:               $brand-white;
$dropdown-link-hover-color:         $brand-darkblue;
$dropdown-link-hover-bg:            $brand-white;

/*
$navbar-height: 80px;
$navbar-padding-vertical: 17px;
$navbar-padding-horizontal: 15px;

$navbar-default-bg: $brand-darkergreen;
$navbar-default-border: transparent;
$navbar-default-link-hover-color:	#000;
$navbar-default-link-active-color:	#000;

$navbar-default-toggle-icon-bar-bg: #fff;
$navbar-default-toggle-hover-bg: darken($navbar-default-bg, 12.5%);
*/


// testimonials-speechbubble overrides
$speechbubble-quote-backgroundcolor:	$highlight-bg-color;
$speechbubble-quote-textcolor:			$highlight-link-color;
$speechbubble-attrib-textcolor:			$highlight-bg-color;

// header contact styles
$contact-entries-label:                 $brand-darkblue;
$contact-entries-link:                  $brand-red;