.custom-logo
{
	display: block;
	max-width: 100%;
    height: auto;

	@media (max-width: $screen-lg-min) {
		@include center-block();
		margin-bottom: 20px;
	}
}

.navbar-header {
	float:none;

	@media (min-width: $screen-lg-min) {
		float: left;
	}
}

.section-topbar {
	background: $brand-red;
	padding-top: 10px;
	padding-bottom: 10px;

	.list-inline {
		margin-bottom: 0;
	}
	
	a {
		display: inline-block;
		color: $brand-white;
		transition: all 100ms;
		padding: 3px 5px;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
			background-color: $brand-grey;
		}
	}
}

header {
	min-height: 350px;
	background: #F4F7F8 url(../images/headerbgtile.gif) repeat 50% top;
	background-size: 300px auto;
}

.navbar {
	margin-top: 20px;
	margin-bottom: 0;
}