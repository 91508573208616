.section-servicesgrid {
    .section-servicesgrid-inner {
        padding-left: 30px;
        padding-right: 30px;
        margin-top: -110px;
        position: relative;
        z-index: 2;
        float: left;
    }

    .col-sm-6 {
        margin-bottom: $standardspacinghalf;
    }
}